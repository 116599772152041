import { IonButton, IonCard, IonCardContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup, IonSpinner } from '@ionic/react';
import { checkmarkCircle, closeCircle } from 'ionicons/icons';
import React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, SmallText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { forwardTo, forwardToDeliveryOption, goBack, isEmptyObject, isWebConfig, sprintf, validateForm } from '../../lib/utils';
import { getNearestLocation, postCodeCheck, saveDeliveryDataTemporarily, setCommonModal, setDeliveryAddress, setPostCodeData, storeDeliveryAddress } from '../../store/actions';
import '../clickAndCollect/index.css';
import './index.css';

class DeliveryAddressCheck extends React.Component {
	state = {
		form: {
			addressLine1: '',
			addressLine2: '',
			place: '',
			postalCode: '',
			driverNotes: null,
		},
		formErrors: {},
		usePostCode: false,
		initial: true,
		checking: false,
		postalCode: '',
		address: '',
		postalCodeValid: false,
		addressValid: true,
		deliveryZone: [],
		deliveryZoneOption: '',
		deliveryPrice: '',
		restaurant: {},
		validPostCode: false,
		checkMarkFlag: null,
		minOrder: 0,
		searchOptions: {
			componentRestrictions: {
				country: getConfig().general?.defaultState || 'UK',
			},
			types: ['address'],
		},
	};

	formConfig = {
		addressLine1: { required: true },
		addressLine2: { required: false },
		place: { required: true },
	};

	handleInput = (key, val) => {
		const form = {
			...this.state.form,
			[key]: val,
		};

		this.setState({
			form,
			formErrors: validateForm(this.formConfig, form),
		});
	};

	handleChange = (address) => {
		this.setState({ address });
	};

	handleSelect = (address) => {
		geocodeByAddress(address)
			.then((results) => {
				if (results[0].address_components[0]?.types.includes('street_number')) {
					this.setState({
						addressValid: true,
						address: results[0].formatted_address,
						form: {
							addressLine1: `${results[0].address_components[0].long_name} ${results[0].address_components[1].long_name}`,
							place: results[0].address_components[2].long_name,
							postalCode: results[0].address_components[results[0].address_components.length - 1].long_name,
						},
					});
					this.check(results[0].address_components[results[0].address_components.length - 1].long_name, this.props.deliveryRangeType);
				} else {
					this.setState({ address: results[0].formatted_address, addressValid: false });
				}
			})
			.catch((error) => new Error(error));
	};

	checkDelivery = () => {
		if (!Basket.getDeliveryOption()) {
			forwardToDeliveryOption();
		}
	};

	componentDidMount() {

		Basket.setOrderType(this.props.location?.state?.scheduled ? 'scheduled-delivery' : 'charter-delivery');
		console.log(this.props.location.state)
		this.checkDelivery();
	}

	componentDidUpdate(prevProps, prevState) {
		this.checkDelivery();
		if (prevState.deliveryZoneOption !== this.state.deliveryZoneOption) {
			this.setState({
				deliveryPrice: this.state.deliveryZone[this.state.deliveryZoneOption].delivery_zone.price,
			});
			const minOrder = this.state.deliveryZone[this.state.deliveryZoneOption].delivery_zone.min_order;
			Basket.setMinOrder(minOrder);
		}
		if (prevProps.checkedCodeData !== this.props.checkedCodeData) {
			if (this.props.checkedCodeData.length > 0 && prevProps.checkedCodeData !== this.props.checkedCodeData) {
				const copiedRestaurants = JSON.parse(JSON.stringify(this.props.restaurants));
				const filteredRestaurants = copiedRestaurants.filter((restaurant) => !restaurant.can_charter_delivery_order);
				let deliveryZone = JSON.parse(JSON.stringify(this.props.checkedCodeData)).filter((el) => filteredRestaurants.every((fd) => fd.id !== el.restaurant_id));
				if (this.props.checkedCodeData.length === 1) {
					this.setState({
						checking: false,
						postalCodeValid: true,
						checkMarkFlag: 'success',
						deliveryZone,
					});
				} else if (this.props.checkedCodeData.length > 1) {
					this.setState(
						{
							checking: false,
							postalCodeValid: true,
							checkMarkFlag: 'success',
							deliveryZone,
						},
						() => { },
					);
				}
			} else if (prevState.checking && this.props.checkedCodeData.length === 0) {
				this.setState({ checking: false, postalCodeValid: false, checkMarkFlag: 'danger' });
			}
		}
	}

	checkPostCode = (value) => {
		// let reg = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/
		// let postalCodeValid = reg.test(value)
		if (value.length >= 3) {
			this.setState({
				postalCodeValid: true,
				postalCode: value,
				initial: false,
				checkMarkFlag: null,
			});
		} else {
			this.setState({ postalCodeValid: false, postalCode: value, initial: false, checkMarkFlag: null }, () => {
				this.props.dispatch(setPostCodeData({ data: [] }));
			});
		}
	};

	setPostalCode = (e) => {
		this.checkPostCode(e.target.value);
	};

	check = (value, type) => {
		const { form, postalCode } = this.state;
		const tempData = {
			addressLine1: form.addressLine1,
			place: form.place,
			postalCode,
		};
		if (form.addressLine2) {
			tempData.addressLine2 = form.addressLine2;
		}

		let charterDelivery = false;
		if (Basket.delivery_option.id === 'charter-delivery') {
			charterDelivery = true;
		}
		if (type === 'postcode') {
			this.props.dispatch(postCodeCheck(value, charterDelivery));
			this.props.dispatch(saveDeliveryDataTemporarily(tempData));
			this.setState({ initial: false, checking: true });
		} else if (type === 'distance' || type === 'polygon') {
			const formattedAddress = this.state.address.length > 0 ? this.state.address : `${form.addressLine1}, ${form.addressLine2}, ${form.place}, ${postalCode}`;
			this.props.dispatch(getNearestLocation(formattedAddress, charterDelivery, Basket.delivery_option.id == 'scheduled-delivery'));
			this.props.dispatch(saveDeliveryDataTemporarily(tempData));
			this.setState({ initial: false, checking: true, restaurant: {}, deliveryPrice: '' });
		} else {
			this.setState({ initial: true }, () => {
				this.props.dispatch(setPostCodeData({ data: [] }));
				this.props.dispatch(saveDeliveryDataTemporarily(tempData));
			});
		}
		this.setState({
			initial: false,
			checking: true,
			formErrors: validateForm(this.formConfig, this.state.form),
		});
	};

	saveAndContinue = () => {
		const { postalCode, restaurant } = this.state;
		const { restaurants } = this.props;
		const minOrder = restaurant.delivery_zone.min_order;
		this.props.dispatch(setDeliveryAddress({ postalCode: postalCode.toUpperCase() }));
		Basket.setRestaurant(restaurants.find((res) => res.id === restaurant.restaurant_id));
		Basket.setServicePercentage(0);

		Basket.setDeliveryPrice(this.state.deliveryPrice);
		this.props.dispatch(storeDeliveryAddress(this.state.form));
		Basket.setDeliveryAddress({ ...this.state.form, postalCode: postalCode });
		Basket.setMinOrder(minOrder);
		forwardTo('/delivery-address-add', { scheduled: this.props.location?.state?.scheduled ? true : false });
	};

	saveAddressAndContinue = () => {
		const { restaurants } = this.props;
		const { restaurant } = this.state;

		Basket.setDeliveryAddress(this.state.form);
		Basket.setRestaurant(restaurants.find((res) => res.id === restaurant.restaurant_id));
		Basket.setServicePercentage(0);
		Basket.setDeliveryPrice(this.state.deliveryPrice);
		forwardTo('/delivery-address-add', { scheduled: this.props.location?.state?.scheduled ? true : false });
	};

	restaurantName = (restaurant) => {
		if (restaurant && restaurant.restaurant_id) {
			return restaurant.restaurant_name;
		}
		return '';
	};

	restaurantAddress = (restaurant) => {
		if (restaurant && restaurant.restaurant_id) {
			return restaurant.restaurant_address;
		}
		return '';
	};

	changeDeliveryZone = (event) => {
		this.setState({
			deliveryZoneOption: event.detail.value,
			error: '',
			restaurant: this.state.deliveryZone[event.detail.value],
		});
	};
	setDeliveryPrice = () => {
		let option = this.state.deliveryZoneOption;
		let deliveryPrice = this.props.checkedCodeData[option].delivery_zone.price;
		this.setState({ deliveryPrice, restaurant: this.props.checkedCodeData[option] }, () => {
			this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false));
		});
	};
	checkAvailableSlotsForToday = (restaurant) => {
		let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		let today = days[moment().format('d')];
		const day = {
			Monday: 'w1',
			Tuesday: 'w2',
			Wednesday: 'w3',
			Thursday: 'w4',
			Friday: 'w5',
			Saturday: 'w6',
			Sunday: 'w0',
		};
		let selectedRestaurant = this.props.restaurants.find((r) => r.id === restaurant.restaurant_id);
		let deliveryTimes = selectedRestaurant.charter_delivery_times_json;

		let flag = false;
		if (!isEmptyObject(deliveryTimes)) {
			for (let i = 0; i < deliveryTimes.length; i++) {
				deliveryTimes[i].availability.forEach((dt) => {
					if (dt.d === day[today]) {
						let addedTime = null;
						addedTime = moment().add(restaurant.charter_delivery_order_slot_lead_time, 'minutes');
						if (addedTime.isBefore(moment(dt.end, 'hh:mm'))) {
							flag = true;
						}
					}
				});
			}
		}
		return flag;
	};
	getAddressString(form) {
		return [form?.addressLine1, form?.addressLine2, form?.place, this.state.postalCode].filter(Boolean).join(', ');
	}
	backHandler = () => {
		const { address_list } = this.props.profile;
		if (address_list && address_list.length > 0) {
			goBack();
		} else {
			forwardTo('/delivery-options');
		}
	};
	render() {
		const { __, checkedCodeData, isChooseDeliveryModalOpen, deliveryRangeType } = this.props;
		const {
			initial,
			checking,
			postalCode,
			address,
			postalCodeValid,
			deliveryZone,
			deliveryZoneOption,
			deliveryPrice,
			checkMarkFlag,
			searchOptions,
			addressValid,
			form,
			formErrors,
			usePostCode,
		} = this.state;
		const animationMenuClass = isChooseDeliveryModalOpen ? 'show-up' : '';
		let formValid = Object.keys(formErrors).length === 0;

		return (
			<Layout backHandler={this.backHandler} title={__('Add new address')} headerWithTitle={true} noPadding color="transparent">
				<div className="absolute-content delivery-address-bg delivery-address-bg-position  "></div>
				<IonCard color="white" className="restaurant-card scrollable-y">
					<IonCardContent className="flex-row-wrapper ">
						<div className="flex-min">
							<Title className="web-only">{__('Add new address')}</Title>
						</div>
						<div>
							{this.state.usePostCode ? (
								<>
									{this.state.deliveryZone.length === 0 || this.state.showFullForm ? (
										<form autoComplete="on">
											<div className="input-field-container">
												<NormalText>{__('Address 1')}</NormalText>
												<IonItem lines="none" className="input-field-wrapper">
													<IonInput
														required={true}
														value={form?.addressLine1}
														autocomplete="street-address"
														onIonChange={(e) => this.handleInput('addressLine1', e.target.value, e)}
														type="text"
														size="50px"
														clearInput
													/>
												</IonItem>
											</div>

											{formErrors.addressLine1 ? <FieldError className="field-error" value={__(formErrors.addressLine1)} /> : null}
											<div className="input-field-container">
												<NormalText>{__('Address 2')}</NormalText>
												<IonItem lines="none" className="input-field-wrapper">
													<IonInput
														value={form?.addressLine2}
														autocomplete="street-address"
														onIonChange={(e) => this.handleInput('addressLine2', e.target.value, e)}
														type="text"
														size="50px"
														clearInput
													/>
												</IonItem>
											</div>

											{formErrors.addressLine2 ? <FieldError className="field-error" value={__(formErrors.addressLine2)} /> : null}
											<div className="input-field-container">
												<NormalText>{__('Town')}</NormalText>
												<IonItem lines="none" className="input-field-wrapper">
													<IonInput
														required={true}
														value={form?.place}
														autocomplete="on"
														onIonChange={(e) => this.handleInput('place', e.target.value, e)}
														type="text"
														size="50px"
														clearInput
													/>
												</IonItem>
											</div>

											{formErrors.place ? <FieldError className="field-error" value={__(formErrors.place)} /> : null}
											<div className="input-field-container">
												<NormalText>{__('Postcode')}</NormalText>
												<IonItem lines="none" className="input-field-wrapper">
													<>
														<IonInput
															className="strong-text"
															required={true}
															value={postalCode?.toUpperCase()}
															autocomplete="postal-code"
															onIonChange={(e) => this.setPostalCode(e)}
															type="text"
															size="50px"
														/>
														{initial || checking || !checkMarkFlag ? null : (
															<IonIcon size="small" color={checkMarkFlag} icon={checkMarkFlag === 'success' ? checkmarkCircle : closeCircle} />
														)}
													</>
												</IonItem>
											</div>
										</form>
									) : (
										<div className="input-field-container">
											<NormalText>{__('Delivery to')}</NormalText>
											<IonItem
												onClick={() => {
													this.setState({ deliveryZone: [] });
												}}
												lines="none"
												className="input-field-wrapper"
											>
												<IonInput value={this.getAddressString(form)} readonly={true} />
											</IonItem>
										</div>
									)}
								</>
							) : (
								<PlacesAutocomplete value={address} onChange={this.handleChange} onSelect={this.handleSelect} searchOptions={searchOptions} shouldFetchSuggestions={address.length > 3}>
									{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
										<div>
											<div className="input-field-container">
												<NormalText>{__('Find your full address')}</NormalText>
												<IonItem lines="none" className="input-field-wrapper">
													<input
														{...getInputProps({
															placeholder: `${__('Search Places')} ...`,
															className: 'location-search-input native-input sc-ion-input-md .sc-ion-label-md-h-custom',
														})}
													/>
												</IonItem>

												<div className="autocomplete-dropdown-container ">
													{loading && <NormalText>{__('Loading...')}</NormalText>}
													{suggestions.map((suggestion, index) => {
														const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
														// inline style for demonstration purpose
														const style = suggestion.active
															? {
																backgroundColor: '#fafafa',
																cursor: 'pointer',
															}
															: {
																backgroundColor: '#ffffff',
																cursor: 'pointer',
															};
														return (
															<div
																className="pac-container pac-logo"
																key={index}
																{...getSuggestionItemProps(suggestion, {
																	className,
																	style,
																})}
															>
																<div className="pac-item">
																	<span className="pac-icon pac-icon-marker"></span>
																	<span className="pac-item-query">
																		<span>{suggestion.description}</span>
																	</span>
																</div>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									)}
								</PlacesAutocomplete>
							)}
							{this.state.usePostCode ? (
								<>
									{this.state.deliveryZone.length === 0 && (
										<IonButton
											fill="clear"
											className="link underlined"
											color="dark"
											onClick={() => {
												this.setState({
													usePostCode: false,
												});
											}}
										>
											{__('Check by address')}
										</IonButton>
									)}
								</>
							) : this.state.deliveryZone.length === 0 ? (
								<IonButton
									fill="clear"
									className="link underlined"
									color="dark"
									onClick={() => {
										this.setState({
											usePostCode: true,
											formErrors: {},
											addressValid: true,
											address: '',
											form: {
												addressLine1: '',
												addressLine2: '',
												place: '',
												postalCode: '',
												driverNotes: null,
											},
										});
									}}
								>
									{__('Address not listed?')}
								</IonButton>
							) : null}
							<Spacer />
							<div className="address-checking-box ">
								{!addressValid ? (
									<>
										<SmallText color="danger">{sprintf(__('Please write the full address in correct form including number'), 'small')}</SmallText>
										{!usePostCode && (
											<IonButton
												className="ion-margin-top"
												expand="block"
												color="primary"
												onClick={() => {
													this.setState({
														usePostCode: true,
														formErrors: {},
														addressValid: true,
														address: '',
														form: {
															addressLine1: '',
															addressLine2: '',
															place: '',
															postalCode: '',
															driverNotes: null,
														},
													});
												}}
											>
												{__('Enter Address Manually')}
											</IonButton>
										)}
									</>
								) : null}
								{initial ? null : checking ? (
									<>
										<br />
										<div>
											<IonSpinner />
										</div>
										<SmallText>{__('Checking nearest locations')}</SmallText>
									</>
								) : postalCodeValid && checkedCodeData.length > 0 && addressValid ? (
									!checkMarkFlag ? null : (
										<>
											<IonList lines="none" className="box-wrapper">
												<IonRadioGroup onIonChange={this.changeDeliveryZone} value={deliveryZoneOption}>
													{deliveryZone
														.sort((a, b) => {
															return a.delivery_zone.price - b.delivery_zone.price;
														})
														.map((restaurant, i) => {
															const { restaurant_name, restaurant_address, delivery_zone } = restaurant;

															const price = restaurant.delivery_zone.price;
															let availableSlosts = this.props.location?.state?.scheduled ? true : this.checkAvailableSlotsForToday(restaurant);

															return (
																<IonItem key={i}>
																	<div tabIndex="-1"></div>
																	<IonRadio disabled={!availableSlosts} color={isWebConfig() ? 'primary' : 'white'} slot="start" value={i} />
																	<IonLabel className="ion-text-wrap" color="dark">
																		<NormalText className="primary-color">{restaurant_name}</NormalText>
																		<SmallText className="block">{restaurant_address}</SmallText>
																		<div className="delivery-additional-restaurant-information">
																				<div>
																				{!Basket.getDeliveryOption()?.hide_delivery_fee && <>
																					<SmallText className='block'>
																						{price > 0
																							? restaurant.delivery_charge_in_percent
																								? `${restaurant_name} Delivery price -  ${price} %`
																								: `${restaurant_name} Delivery price - ${Basket.getCurrency().label} ${price}`
																							: `${restaurant_name}  -  ${__('Free Delivery')}`}
																					</SmallText>
																				</>}
																				{!Basket.getDeliveryOption()?.hide_minimum_spend && <>
			
																					<SmallText className="block">
																						{__('Minimun Order')}:{Basket.formatPrice(delivery_zone.min_order)}
																					</SmallText>
																				</>}
																				</div>
																			<SmallText className="restaurant-opening-time">
																				{' '}
																				{!availableSlosts ? __('Service Unavailable') : ` ${__('OPEN')}`}
																			</SmallText>
																		</div>
																	</IonLabel>
																</IonItem>
															);
														})}
												</IonRadioGroup>
											</IonList>
										</>
									)
								) : checkedCodeData.length === 0 && checkMarkFlag && addressValid ? (
									<>
										<Spacer size={1} />
										<SmallText color="danger centered">{__('Unfortunately, we do not deliver to you yet')}</SmallText>
										{!usePostCode && (
											<IonButton
												expand="block"
												className="ion-margin-top"
												color="primary"
												onClick={() => {
													this.setState({
														usePostCode: true,
														formErrors: {},
														addressValid: true,
														address: '',
														form: {
															addressLine1: '',
															addressLine2: '',
															place: '',
															postalCode: '',
															driverNotes: null,
														},
													});
												}}
											>
												{__('Enter Address Manually')}
											</IonButton>
										)}
									</>
								) : null}
							</div>
						</div>
						<Spacer size={1} />
						<div className="flex-min">
							{postalCodeValid && this.state.deliveryZone.length > 0 && checkMarkFlag && addressValid ? (
								this.state.usePostCode ? (
									<IonButton disabled={!postalCodeValid || deliveryPrice === '' || !formValid} expand="block" color="primary" onClick={this.saveAndContinue}>
										{__('Continue')}
									</IonButton>
								) : (
									<IonButton disabled={!postalCodeValid || deliveryPrice === ''} expand="block" color="primary" onClick={this.saveAddressAndContinue}>
										{__('Save address and Continue')}
									</IonButton>
								)
							) : this.state.usePostCode ? (
								<IonButton
									className="no-margin"
									disabled={!postalCodeValid || !formValid || form.addressLine1 == '' || form.place == ''}
									expand="block"
									color="primary"
									onClick={() => {
										this.check(postalCode, deliveryRangeType);
									}}
								>
									{__('Check Postcode')}
								</IonButton>
							) : null}
						</div>
					</IonCardContent>
				</IonCard>
				<div
					className="click-collect-pickers-backdrop"
					style={{ display: isChooseDeliveryModalOpen ? '' : 'none' }}
					onClick={() => this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))}
				></div>
				<div className={`click-collect-dialog ${animationMenuClass}`}>
					<div className="click-collect-dialog-layout sc-ion-modal-md">
						<div className="click-collect-dialog-header">
							<h3>{__('Choose delivery')}</h3>
						</div>
						<div
							className="click-collect-dialog-closer"
							style={{ position: 'absolute', right: 0, top: 0 }}
							onClick={() => this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))}
						>
							<ion-icon name="close" role="img" class="md hydrated" aria-label="close"></ion-icon>
						</div>
						<div className="click-collect-dialog-content">
							<IonList lines="full" className="box-wrapper">
								<IonRadioGroup onIonChange={this.changeDeliveryZone} value={deliveryZoneOption}>
									{deliveryZone
										.sort((a, b) => {
											return a.delivery_zone.price - b.delivery_zone.price;
										})
										.map((restaurant, i) => {
											const { restaurant_name } = restaurant;
											const price = restaurant.delivery_zone.price;
											return (
												<IonItem key={i} lines="none">
													<div tabIndex="-1"></div>
													<IonRadio color={isWebConfig() ? 'primary' : 'white'} slot="start" value={i} />
													<IonLabel className="ion-text-wrap" color="dark">
														{price > 0
															? `${restaurant_name}
                                delivery price -
                                ${Basket.getCurrency().label}${price}`
															: `${restaurant_name} - ${__('Free Delivery')}`}
													</IonLabel>
												</IonItem>
											);
										})}
								</IonRadioGroup>
							</IonList>
						</div>
						<div className="click-collect-dialog-action">
							<IonButton
								disabled={deliveryZoneOption === ''}
								expand="block"
								color="primary"
								onClick={() => {
									this.setDeliveryPrice();
								}}
							>
								{__('Continue')}
							</IonButton>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

const stateToProps = (state) => {
	const { profile, auth } = state.profile;
	const { checkedCodeData } = state.orders;
	const { deliveryRangeType, isChooseDeliveryModalOpen } = state.common;
	const { restaurants } = state.restaurants;
	return {
		profile,
		auth,
		checkedCodeData,
		deliveryRangeType,
		isChooseDeliveryModalOpen,
		restaurants,
	};
};

export default connect(stateToProps)(withTranslation(DeliveryAddressCheck));
