import React from 'react';
import { withRouter } from 'react-router';
import { NormalText, Subtitle } from '../common';
import { withTranslation } from '../../lib/translate';
import './index.css';

const BlockSeparator = (props) => {
  const { __, text } = props;
	return (
		<div className="block-separator">
      <div className="separator-line"></div>
      <Subtitle className='bold'>{__(text ? text : 'or')}</Subtitle>
      <div className="separator-line"></div>
    </div>
	);
};

export default withRouter(withTranslation(BlockSeparator));
