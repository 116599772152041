import { loginRequest, registerRequest, setFullRegisterForm, showToast } from '../../store/actions';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { IonButton, IonIcon, isPlatform } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import { logoApple } from 'ionicons/icons';
import { connect } from 'react-redux';
import React from 'react';
import './index.css';

class SocialLogin extends React.Component {
  constructor(props) {
    super(props);
    this.googleButtonRef = React.createRef();
    this.state = {
      googleButtonWidth: null,
    };
  }

  componentDidMount() {
    const { appleClientId, redirectUrl } = getConfig()?.socialLogin || {};
    if (appleClientId && isPlatform('ios') && window.AppleID) {
      window.AppleID.auth.init({
        clientId: appleClientId || '',
        redirectURI: redirectUrl || '',
        scope: 'email',
        state: 'appleLogin',
        usePopup: true,
      });
    }
    setTimeout(() => {
      if (this.googleButtonRef.current) {
        this.setState({ googleButtonWidth: `${this.googleButtonRef.current.clientWidth}px` });
      }
    }, 200);
  }

  onSuccess(response) {
    const { credential } = response;
    const {
      __,
      dispatch,
      validateForm,
      isRegister,
      protectedReferrer,
      registerFormData,
    } = this.props;
    const isFormValid = validateForm ? validateForm(registerFormData) : true;
    if (isFormValid) {
      if (!response.error) {
        if (isRegister) {
          dispatch(setFullRegisterForm(registerFormData));
          dispatch(
            registerRequest({
              referrer: '/register',
              socialAuth: { tokenId: credential, authType: 'Google' },
            }),
          );
        } else {
          dispatch(
            loginRequest({
              username: '',
              referrer: protectedReferrer,
              socialAuth: { tokenId: credential, authType: 'Google' },
            }),
          );
        }
      } else {
        dispatch(showToast(__('Error logging in with Google!'), 'warning'));
      }
    }
  }

  onAppleSuccess() {
    const {
      __,
      dispatch,
      validateForm,
      isRegister,
      protectedReferrer,
      registerFormData,
    } = this.props;
    const isFormValid = validateForm ? validateForm(registerFormData) : true;
    if (window.AppleID && isFormValid) {
      window.AppleID.auth.signIn().then((response) => {
        if (!response.error) {
          const { id_token, code } = response.authorization;
          let email = '';
          if (response.user && response.user.email) {
            email = response.user.email;
          }
          if (isRegister) {
            dispatch(setFullRegisterForm(registerFormData));
            dispatch(
              registerRequest({
                referrer: '/register',
                socialAuth: { email: email, tokenId: id_token, code: code, authType: 'Apple' },
              }),
            );
          } else {
            dispatch(
              loginRequest({
                username: '',
                referrer: protectedReferrer,
                socialAuth: { email: email, tokenId: id_token, code: code, authType: 'Apple' },
              }),
            );
          }
        } else {
          dispatch(showToast(__('Error logging in with Apple!'), 'warning'));
        }
      });
    }
  }

  render() {
    const { googleClientId, appleClientId, redirectUrl } = getConfig()?.socialLogin || {};
    const { __, onGoogleSuccess, onGoogleFailure, onAppleSuccess } = this.props;
    const { googleButtonWidth } = this.state;

    return (
      <div className='social-login-wrapper' ref={this.googleButtonRef}>
        {googleClientId && googleButtonWidth && (isPlatform('android') || isPlatform('desktop')) && (
          <GoogleOAuthProvider clientId={googleClientId || ''}>
            <div className="google-login-button" expand="block">
              <GoogleLogin
                onSuccess={(response) =>
                  onGoogleSuccess ? onGoogleSuccess(response) : this.onSuccess(response)
                }
                onFailure={(response) =>
                  onGoogleFailure ? onGoogleFailure(response) : this.onSuccess(response)
                }
                cookiePolicy="single_host_origin"
                shape="circle"
                context="signin"
                logo_alignment="center"
                type="standard"
                text="signin_with"
                theme="outline"
                size="large"
                width={googleButtonWidth || '1000px'}
              />
            </div>
          </GoogleOAuthProvider>
        )}
        {appleClientId && isPlatform('ios') && (
          <IonButton
            expand="block"
            color="black"
            className="round-button"
            onClick={() => (onAppleSuccess ? onAppleSuccess() : this.onAppleSuccess())}
          >
            <IonIcon slot="start" icon={logoApple} /> Sign in with Apple
          </IonButton>
        )}
      </div>
    );
  }
}

const stateToProps = (state) => {
  const { registerFormData } = state.profile;
  return {
    registerFormData,
  };
};

export default connect(stateToProps)(withTranslation(SocialLogin));
