import { IonButton, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { NormalText, Spacer, Title } from '../../components/common';
import Layout from '../../components/layout';
import Mobiscroll from '../../components/mobiscroll';
import NoData from '../../components/noData';
import Loading from '../../components/spinner';
import api from '../../lib/api';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { cutoffTime, deepCopy, forwardTo, forwardToDeliveryOption, isArray, isDefined, isEmptyObject, isObject, isWebConfig, parseAllergenData, goBack, groupBy, weekdays } from '../../lib/utils';
import { checkSnoozedTimes, formatDataForTime, getMenusForSelectedTime, isStoreOpened } from '../../screens/clickAndCollect';
import { loading, setDeliveryOption, setDeliveryTime } from '../../store/actions';
import { setCommonModal, showToast } from '../../store/common/actions';
import { SET_RESTAURANT_PROP } from '../../store/constants';
import { getIkentooMenu, getIkentooMenusForLocation, passedTheOrder } from '../../store/restaurants/actions';
import { filterMenu } from '../../store/restaurants/sagas';
import 'moment-timezone';
import './index.css';
import 'moment-timezone';
import mobiscroll from '@mobiscroll/react';

const { SelectOption } = Mobiscroll;

class ScheduledDeliveryTime extends React.Component {
	pickerRef = React.createRef();
	pickerRefCharter = React.createRef();
	state = {
		selectedTime: null,
		pickTime: null,
		formatted: null,
		selectedTimeOption: null,
		continueButtonClicked: false,
		showOnlyPickUpText: false,
		restaurantIsClosed: false,
	};


	getMenuForReorder = (menus, reorderItems) => {
		let menuForReorder = [];
		for (let i = 0; i < menus.length; i++) {
			let foundItems = 0;
			const categoryItems = menus[i].menuEntry || menus[i].menuEntryGroups || null;
			let items = Basket.flattenMenuItems(deepCopy(categoryItems));
			menus[i].flattenMenuItems = items;
			if (items.length > 0) {
				for (let j = 0; j < reorderItems.length; j++) {
					let foundItem = items.find((i) => i.sku === reorderItems[j].item.sku);
					if (foundItem) {
						foundItems = foundItems + 1;
					}
				}
			}
			menus[i].foundItems = foundItems;
			menuForReorder.push(menus[i]);
		}
		menuForReorder.sort((a, b) => b.foundItems - a.foundItems);
		if (menuForReorder[0] && menuForReorder[0].foundItems > 0) {
			return filterMenu(menuForReorder[0]);
		} else {
			return false;
		}
	};
	confirmTime = async () => {
		const { dispatch } = this.props;
		const { pickTime } = this.state;
		this.setState({ continueButtonClicked: true }, async () => {
			const choosenRestaurant = Basket.getRestaurant()
			const businessLocationId = choosenRestaurant.business_location_id;

			if (this.props.location.state && this.props.location.state.selectedRestaurant) {
				let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);
				const scheduledMenuId = choosenRestaurant.active_menus?.scheduled_delivery?.menu_id;
				ikentooMenusForLocation = ikentooMenusForLocation.filter((menu) => menu.ikentooMenuId == scheduledMenuId);
				if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
					let ikentooMenus = [];
					try {
						for (let i = 0; i < ikentooMenusForLocation.length; i++) {
							ikentooMenus.push(await api.getIkenooMenu(ikentooMenusForLocation[i].ikentooMenuId, businessLocationId));
						}
					} catch (error) {
						this.setState({ error: 'Get restaurant menu error.' });
						this.setState({ continueButtonClicked: false });
					}
					const reorderItems = this.props.location.state.selectedRestaurant.items;
					const menu = this.getMenuForReorder(ikentooMenus, reorderItems);
					if (menu) {
						dispatch({
							type: SET_RESTAURANT_PROP,
							key: 'ikentooMenu',
							value: menu,
						});
						if (menu.flattenMenuItems.length > 0) {
							let validationStatus = {
								notBasketEmpty: false,
								validationError: false,
							};
							reorderItems.map((newBasketItem) => {
								let foundItem = menu.flattenMenuItems.find((i) => i.sku === newBasketItem.item.sku);
								if (foundItem && Basket.isProductEnabled(foundItem)) {
									if (newBasketItem.selectedChoices[0]) {
										newBasketItem.selectedChoices[0] = newBasketItem.selectedChoices[0].filter((el) =>
											getConfig().flags.showSnoozedProducts ? Basket.isProductJustEnabled(el) : Basket.isProductEnabled(el),
										);
									}
									newBasketItem.item = foundItem;
									Basket.addToBasket(newBasketItem);
									let item = newBasketItem.item;
									let allergens = this.props.allergens;
									let profile = this.props.profile;
									let newArr = parseAllergenData(profile, item, allergens);
									let allergensCodes = newBasketItem.item?.itemRichData?.allergenCodes.length > 0 ? newBasketItem.item.itemRichData.allergenCodes : [];
									if (allergensCodes.length > 0) {
										let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
										Basket.setAllergen(allergensData);
									}
									validationStatus.notBasketEmpty = true;
								} else {
									validationStatus.validationError = true;
								}
							});
							if (validationStatus.notBasketEmpty) {
								if (validationStatus.validationError) {
									dispatch(showToast('Some items were not added to your basket as they are currently unavailable', 'warning'));
									this.setState({ continueButtonClicked: false });
								}
								forwardTo('/order-summary', {
									skipBackToThePreviousPage: false,
								});
							} else {
								dispatch(showToast('Some items were not added to your basket as they are currently unavailable', 'warning'));
								this.setState({ continueButtonClicked: false });
							}
						}
					} else {
						dispatch(showToast('Menu not found', 'warning'));
						this.setState({ continueButtonClicked: false });
					}
				} else {
					dispatch(showToast('Menu not found', 'warning'));
					this.setState({ continueButtonClicked: false });
				}
			} else {
				dispatch(
					getIkentooMenusForLocation(businessLocationId, {
						pickTime,
						json_time_selector:
							[{
								menuId: choosenRestaurant?.active_menus.scheduled_delivery?.menu_id,
								availability: Object.keys(choosenRestaurant?.active_menus.scheduled_delivery?.slots).map((slot) => {
									if (choosenRestaurant?.active_menus.scheduled_delivery?.slots[slot].start_time) {
										return {
											d: weekdays[slot],
											start: choosenRestaurant?.active_menus.scheduled_delivery?.slots[slot].start_time,
											end: choosenRestaurant?.active_menus.scheduled_delivery?.slots[slot].end_time,
										};
									}
								}),
							}]

					}),
				);
				this.setState({ continueButtonClicked: false });
			}

		})
	};


	formatDayName = (name, key) => {
		if (name.includes('Today')) {
			name = 'Today';
		} else if (name.includes('Tomorrow')) {
			name = 'Tomorrow';
		} else {
			name = key;
		}
		return name;
	};


	formatTimesForTree = (times) => {
		const groupByMonth = groupBy(['date']);
		const grouped = groupByMonth(times);
		return grouped;
	};

	formatDeliveryTimes = (store) => {
		const formatSelectOptionDays = [];
		const daysAhead = store?.active_menus?.scheduled_delivery?.days_ahead + 1 || 8
		let picker = [];
		const timezone = getConfig().timezone;
		Array(daysAhead)
			.fill('')
			.forEach((day, i) => {
				let formatDay = moment().tz(timezone).add(i, 'days');
				if (i > 0) {
					formatDay = moment().tz(timezone).add(i, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
				}

				formatDay = moment().tz(timezone).add(i, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

				let formatDayName = formatDay.format('dddd');
				formatSelectOptionDays.push({ formatDayName, formattedDT: formatDay });
			});

		formatSelectOptionDays.map((option) => {
			const snoozedTimes = store?.snoozed_times['scheduled-delivery'] || [];
			const daySlot = formatDataForTime(store, option.formattedDT, this.state.selectedRestaurant, false, false, snoozedTimes, false, this.props.__, true) || [];
			daySlot.forEach((slot) => {
				let h = parseInt(slot.text.split(':')[0]);
				let m = parseInt(slot.text.split(':')[1]);
				let formattedDT = moment(option.formattedDT.hours(h).minutes(m));

				picker.push({
					formattedDT: formattedDT,
					// value: formattedDT.toDate(),
					date: formattedDT.format('ddd, DD MMM'),
					time: formattedDT.format('h:mm A'),
					slot: slot.text,
				});
			});
		});

		let unavailable_dates = store?.active_menus?.scheduled_delivery.unavailable_dates.map((slot) => moment(slot, 'DD/MM/YYYY').format('ddd, DD MMM'));


		const formattedTreeDataSource = this.formatTimesForTree(picker);
		Object.keys(formattedTreeDataSource).map((day) => {
			if (formattedTreeDataSource[day][0].slot === 'CLOSED') {
				delete formattedTreeDataSource[day];

			} else {

				if (unavailable_dates && unavailable_dates.length > 0 && unavailable_dates.indexOf(day) > -1) {
					formattedTreeDataSource[day] = [{ time: 'CLOSED' }];
				}
				let slot = store?.active_menus?.scheduled_delivery?.slots[moment(day, 'ddd, DD MMM').format('dddd').toLowerCase()];
				if (slot?.start_time && store?.active_menus?.scheduled_delivery) {
					let cutoff_time = moment(day, 'ddd, DD MMM')
						.hours(parseInt(slot.start_time.split(':')[0]))
						.minutes(parseInt(slot.start_time.split(':')[1]))
						.seconds(0)
						.add(-slot.prep_time, 'hours');
					if (moment().tz(timezone).isAfter(cutoff_time)) {
						delete formattedTreeDataSource[day];
					}
				}
			}
		});
		const snoozedTimes = store?.snoozed_times['scheduled-collection'] || [];
		snoozedTimes.map((snoozeTime) => {
			let _snoozeTime = moment.utc(snoozeTime, 'ddd, DD MMM YYYY-HH:mm').local();
			let temp_data = formattedTreeDataSource[_snoozeTime.format('ddd, DD MMM')];
			let pos = temp_data?.findIndex((item) => item.slot === _snoozeTime.format('HH:mm'));
			if (pos > -1) {
				temp_data[pos] = { ...temp_data[pos], snoozed: true };
			}
		});
		this.timePickerOptions = formattedTreeDataSource;
	};
	parseSelectedTime(selected) {
		let selectedTimeSlot = selected.match(/\d{1,2}:\d{2} (AM|PM)/)?.[0] || 'CLOSED';
		const selectedDate = selected.replace(' ' + selectedTimeSlot, '');
		const timePickerOptionsKeys = Object.keys(this.timePickerOptions);
		const selectedTime =
			selectedDate === 'init'
				? this.timePickerOptions && timePickerOptionsKeys.length > 0
					? this.timePickerOptions[timePickerOptionsKeys[0]][0]
					: {}
				: this.timePickerOptions[selectedDate].find((option) => option.time === selectedTimeSlot);
		if (selectedTime && !this.state.timeSelected) {
			this.setState({ pickTime: selectedTime?.snoozed ? null : selectedTime.slot }, () => {
				Basket.setCollectionTime(selectedTime.formattedDT);

				Basket.setOrderType('scheduled-delivery');
			});
		}
	}
	render() {
		const { __, deliveryOption } = this.props;
		const { pickTime, restaurantIsClosed } = this.state;
		const restaurant = Basket.getRestaurant();


		this.formatDeliveryTimes(restaurant);
		const additionalDeliverySettings = restaurant?.additional_delivery_settings?.['scheduled-delivery'] || {}
		return (
			<Loading>
				<Layout title={__('Delivery Time')} headerWithTitle={true} color="transparent">
					<div className="flex-row-wrapper absolute-content">
						<div className="scrollable-y">
							<Title className="web-only">
								{__('Delivery Time')}
							</Title>

							<div className="time-picker-wrapper box-wrapper click-and-collect-timepicker-wrapper">
								<mobiscroll.Treelist
									display="inline"
									layout="liquid"
									showInput={false}
									circular={false}
									ref={this.timeSlotRef}
									focusOnClose={false}
									themeVariant="light"
									theme="ios"
									width={(280, 260)}
									placeholder={__('Select Collection Time')}
									onSet={(e, instance) => {
										// Workaround for treelist picker if date is only updated
										const newInstance = new Object(instance);
										instance.setVal(newInstance._tempWheelArray, true, true, true);
										this.parseSelectedTime(instance._tempValue);
									}}
									onInit={() => this.parseSelectedTime('init')}
									scrollLock
								>
									{Object.keys(this.timePickerOptions).map((date, i) => (
										<li key={i} data-val={date}>
											<span className={`item-date-picker ${this.timePickerOptions[date][0].time === 'CLOSED' && 'striked'}`}>{date}</span>
											<ul>
												{this.timePickerOptions[date].map((time, j) => (
													<li key={j} data-val={time.time}>
														<span className={`item-date-picker ${time.snoozed && 'striked'}`}>{time.time === 'CLOSED' ? 'UNAVAILABLE' : time.time}</span>
													</li>
												))}
											</ul>
										</li>
									))}
								</mobiscroll.Treelist>
							</div>
							{(additionalDeliverySettings && additionalDeliverySettings.hasPickUpText) &&

								<div className="click-and-collect-title">
									<Spacer size={1} />
									<div dangerouslySetInnerHTML={{ __html: additionalDeliverySettings.pickUpText }} />
								</div>
							}
						</div>
						<div className="flex-min">
							<IonButton
								disabled={restaurantIsClosed || !pickTime}
								expand="block"
								color="primary"
								className={this.state.continueButtonClicked ? 'unclicked' : ''}
								onClick={() => this.confirmTime()}
							>
								{__('Continue')}
							</IonButton>
						</div>
					</div>

				</Layout>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	const { deliveryOption, deliveryAddress, pickUpPoint } = state.orders;
	const { isChooseMenuModalOpen } = state.common;
	const { ikentooMenusForLocation, restaurants } = state.restaurants;
	return {
		deliveryOption,
		deliveryAddress,
		pickUpPoint,
		isChooseMenuModalOpen,
		ikentooMenusForLocation,
		restaurants,
	};
};

export default connect(stateToProps)(withTranslation(ScheduledDeliveryTime));
