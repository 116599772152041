import { IonAlert, IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonList, IonRadioGroup } from '@ionic/react';
import { card, cardOutline, removeCircleOutline, trashBinOutline } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { NormalText, Spacer, Title } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import { forwardTo, isDefined } from '../../lib/utils';
import { removePaymentCard, updateProfile } from '../../store/actions';
import CardList from '../../components/cardList';
import './index.css';

class Cards extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			removeCardAlert: null,
			selectPaymentCard: this.props.profile.cardToken || null,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.profile.cardToken !== this.props.profile.cardToken) {
			this.setState({ selectPaymentCard: this.props.profile.cardToken });
		}
	}

	changeDefaultPaymentCard = (event) =>
		this.setState({ selectPaymentCard: event.detail.value }, () => {
			this.props.dispatch(updateProfile({ cardToken: event.detail.value }, true));
		});

	removePaymentCard = () => {
		const { __ } = this.props;
		this.props.dispatch(
			removePaymentCard(this.state.removeCardAlert, {
				__,
				cb: () => this.setState({ removeCardAlert: null }),
			}),
		);
	};

	handleRemoveCardAlert = (cardId) => this.setState({ removeCardAlert: cardId });

	render() {
		const { __, cards } = this.props;
		const { removeCardAlert } = this.state;

		return (
			<Loading transparent>
				<Layout color="transparent" title={__('Your Credit Cards')} headerWithTitle={false}>
					<div className="flex-row-wrapper absolute-content">
						<div className="scrollable-y">
							<Title>{__('Your Credit Cards')}</Title>
							{cards.length > 0 ? (
								<CardList />
							) : (
								<div className="cards-list-card box-content no-cards-box">
									<div className="cards-list-card-image">
										<IonIcon icon={cardOutline}></IonIcon>
									</div>
									<IonLabel className="ion-text-wrap">
										<NormalText className="single-item">{__('No payment cards added')}</NormalText>
									</IonLabel>
								</div>
							)}
							<Spacer />
							<IonButton
								expand="block"
								color="primary"
								onClick={() =>
									forwardTo('/card-add', {
										...(this.props.location && this.props.location.state ? this.props.location.state : {}),
									})
								}
							>
								{cards.length > 0 ? __('Or add another payment card') : __('Add payment card')}
							</IonButton>
						</div>
					</div>
				</Layout>
				<IonAlert
					isOpen={isDefined(removeCardAlert)}
					onDidDismiss={() => this.handleRemoveCardAlert(null)}
					header={__('Confirm')}
					message={__('Do you you want to remove this card?')}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
						},
						{
							text: __('Remove'),
							handler: () => this.removePaymentCard(),
						},
					]}
				/>
			</Loading>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		cards: store.orders.cards || [],
		profile: store.profile.profile || {},
	};
};

export default connect(mapStateToProps)(withTranslation(Cards));
